<template>
  <div class="main-content">
    <b-skeleton-wrapper :loading="appointments.length < 1">
      <template #loading>
        <b-skeleton-table
          :rows="5"
          :columns="4"
          :table-props="{ bordered: true, striped: true }"
        ></b-skeleton-table>
      </template>

      <b-table
        :items="appointments"
        :fields="appointmentFields"
        :busy="appointmentsTableBusy"
        sticky-header="100vh"
        bordered
        hover
        responsive
        show-empty
      >
        <template #table-caption>
          <i class="fa fa-info-circle"></i>
          All appointments
        </template>

        <template #table-busy>
          <div class="text-center text-info my-2">
            <b-spinner class="align-middle mr-2"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>

        <template #cell(index)="data">
          {{ data.index + 1 }}
        </template>

        <template #cell(appointment_date)="data">
          {{ data.value ? formatFancyDateFull(data.value) : data.value }}
        </template>

        <template #cell(next_visit)="data">
          {{ data.value ? formatFancyDateFull(data.value) : data.value }}
        </template>

        <template #cell(status)="data">
          <b-badge
            :variant="
              statusClass(
                data.item.status,
                data.item.check,
                data.item.firstpart_sub_time
              ).variant
            "
          >
            {{
              statusClass(
                data.item.status,
                data.item.check,
                data.item.firstpart_sub_time
              ).text
            }}
          </b-badge>
        </template>

        <template #cell(action)="data">
          <b-button-group>
            <b-btn
              size="sm"
              variant="dark"
              v-if="data.item.id"
              @click="previewAppointment(data.item.id)"
              title="Quick View"
              v-b-tooltip.hover
            >
              Preview
            </b-btn>
          </b-button-group>
        </template>
      </b-table>

      <div class="d-md-flex justify-content-between">
        <span> Total: {{ appointmentList ? appointmentList.total : 0 }} </span>

        <b-pagination-nav
          v-if="appointmentList && appointmentList.data"
          :link-gen="linkGen"
          :number-of-pages="appointmentList.last_page"
          first-text="First"
          prev-text="Prev"
          next-text="Next"
          last-text="Last"
          limit="10"
          align="right"
          last-number
          use-router
        ></b-pagination-nav>
      </div>
    </b-skeleton-wrapper>

    <b-modal id="preview-appointment" size="xl" scrollable centered>
      <template #modal-title>
        <small>
          Appointment for
          {{
            selectedAppointment
              ? `${selectedAppointment.patient.firstname} ${
                  selectedAppointment.patient.lastname
                } on ${formatFancyDate(selectedAppointment.appointment_date)}`
              : ""
          }}
        </small>
      </template>

      <div v-if="selectedAppointment">
        <preview-appointment
          id="appointmentPreview"
          :appointment="selectedAppointment"
        ></preview-appointment>
      </div>

      <template #modal-footer="{ cancel }">
        <!-- <b-button size="sm" variant="primary" @click="printAppointmentPreview()">
          Print
        </b-button> -->
        <b-button size="sm" variant="dark" @click="cancel()"> Close </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { geroendpoints } from "@/utils/endpoints";
import { gerohttp } from "@/utils/gerocarehttp";
import pagination from "@/mixins/pagination";

export default {
  name: "AppointmentVisitTracker",
  props: ["patientId"],
  mixins: [pagination],
  components: {
    "preview-appointment": () =>
      import("@/components/partials/PreviewAppointment"),
  },
  data() {
    return {
      appointmentFields: [
        {
          key: "index",
          label: "S/N",
        },
        {
          key: "appointment_date",
          label: "Date",
        },
        {
          key: "next_visit",
          label: "Next Expected Date",
        },
        {
          key: "status",
          label: "Status",
        },
        "action",
      ],
      appointmentList: null,
      appointmentsTableBusy: false,
      query: "filter[patient_id]=:patientId",
      selectedAppointment: null,
    };
  },
  computed: {
    appointments() {
      return this.appointmentList?.data || [];
    },
  },
  methods: {
    fetchAll() {
      this.appointmentsTableBusy = true;
      this.query = this.query.replace(":patientId", this.patientId);

      gerohttp
        .get(geroendpoints.FETCH_APPOINTMENTS + `?${this.query}`)
        .then((response) => {
          this.appointmentList = response;
        })
        .catch((error) => {
          this.$toast.error(
            error.response?.data?.message || error.response?.message
          );
        })
        .finally(() => {
          this.appointmentsTableBusy = false;
        });
    },
    statusClass(status, check, summaryStatus) {
      if (status == 0) {
        return {
          text: "completed",
          variant: "success",
        };
      }

      if (status == 1) {
        if (check == 0) {
          return {
            text: "not checked in",
            variant: "dark",
          };
        }

        if (check == 1) {
          return {
            text: "checked in",
            variant: "dark",
          };
        }

        if (check == 2 && summaryStatus) {
          return {
            text: "report submitted",
            variant: "info",
          };
        }

        if (check == 2) {
          return {
            text: "checked out",
            variant: "dark",
          };
        }
      }

      if (status == 2) {
        return {
          text: "doctor rescheduled",
          variant: "primary",
        };
      }

      if (status == 3) {
        return {
          text: "doctor rescheduled",
          variant: "primary",
        };
      }

      return {
        text: "",
        variant: "",
      };
    },
    previewAppointment(appointmentId) {
      gerohttp
        .get(geroendpoints.FETCH_APPOINTMENT.replace(":id", appointmentId), {
          params: {
            include: "patient_doctor_user",
          },
        })
        .then((response) => {
          this.selectedAppointment = response;
          this.$bvModal.show("preview-appointment");
        })
        .catch((error) => {
          this.$toast.error(
            error.response?.data?.message || error.response?.message
          );
        });
    },
  },
};
</script>
